import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import ImprintPrivacyOverlay from '../components/ImprintPrivacyOverlay'
import modalStyle from '../styles/modal-style'
import Close from '../img/close.svg'

function ContactSlideMobile(props) {
  const { contactData, imprintLabel, locale } = props
  const [imprintModalOpen, setImprintModalOpen] = React.useState(false)

  const openImprintModal = () => {
    setImprintModalOpen(true)
  }

  const closeImprintModal = () => {
    setImprintModalOpen(false)
  }

  return (
    <section className={`hero contact contact--mobile`}>
      <div className="hero-body contact__inner">
        <div
          className="contact__address"
          dangerouslySetInnerHTML={{ __html: contactData[0].address.html }}
        />
        <div className="contact__links">
          <br />
          <p>
            <a href={`mailto:${contactData[0].email}`}>E-mail</a>
          </p>
          <br />
          <p>
            <a href={`tel:${contactData[0].telephone}`}>Tel. {contactData[0].telephone}</a>
          </p>
          <p>
            <a href={`fax:${contactData[0].fax}`}>Fax {contactData[0].fax}</a>
          </p>
        </div>
        <div className="contact__imprint">
          <p>
            <a onClick={openImprintModal}>{`${imprintLabel}`}</a>
          </p>
        </div>
      </div>
      <Modal
        isOpen={imprintModalOpen}
        onRequestClose={closeImprintModal}
        contentLabel={`${imprintLabel}`}
        style={modalStyle}
        closeTimeoutMS={400}
      >
        <a className="overlay-close" onClick={closeImprintModal}>
          <Close />
        </a>
        <ImprintPrivacyOverlay locale={locale} />
      </Modal>
    </section>
  )
}

export default ContactSlideMobile
