import React from 'react'
import ImageLoader from '../components/ImageLoader'
import { getSrcSetUrl, getUncompressedUrl } from '../utils/helpers'

const LawyerOverlay = ({ lawyer }) => {
  const url = lawyer.profile_image.url
  return (
    <div className="lawyer-overlay">
      <div className="lawyer-overlay__image">
        <ImageLoader
          src={getUncompressedUrl(url)}
          alt={lawyer.name}
          srcSet={`${getSrcSetUrl(url, 2400)} 2400w, ${getSrcSetUrl(
            url,
            1920
          )} 1920w, ${getSrcSetUrl(url, 1440)} 1440w, ${getSrcSetUrl(
            url,
            1280
          )} 1280w, ${getSrcSetUrl(url, 1024)} 1024w, ${getSrcSetUrl(url, 640)} 640, ${getSrcSetUrl(
            url,
            480
          )} 480`}
        />
      </div>
      <div className="lawyer-overlay__text">
        <p>{lawyer.name}</p>
        <p>{lawyer.position}</p>
        <br />
        <div dangerouslySetInnerHTML={{ __html: lawyer.profile_text.html }} />
        <br />
        <p>
          <a href={`phone:${lawyer.phone}`}>{lawyer.phone}</a>
          <br />
          <a href={`mailto:${lawyer.email}`}>{lawyer.email}</a>
        </p>
      </div>
    </div>
  )
}

export default LawyerOverlay
