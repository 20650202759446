import React from 'react'

function ExpertiseSlideDesktop(props) {
  const { contentColumnsData } = props
  return (
    <section className="hero section expertise">
      <div className="hero-body">
        <div className="container expertise__inner">
          <div className="columns is-multiline is-gapless">
            {contentColumnsData &&
              contentColumnsData.map((item, i) => (
                <div
                  dangerouslySetInnerHTML={{ __html: item.content_column.html }}
                  className="column is-half"
                  key={`home-content-column--${i}`}
                ></div>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExpertiseSlideDesktop
