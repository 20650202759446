import React from 'react'

function ExpertiseSlideMobile(props) {
  const html = props.content_column && props.content_column.html
  return (
    <section className="hero section expertise">
      <div className="hero-body expertise__inner">
        {html && (
          <div
            dangerouslySetInnerHTML={{
              __html: html
            }}
          ></div>
        )}
      </div>
    </section>
  )
}

export default ExpertiseSlideMobile
