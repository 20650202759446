import React, { useState, useEffect } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import IntroGallerySlider from './IntroGallerySlider'
import ExpertiseSlideMobile from './ExpertiseSlideMobile'
import LawyerSlideMobile from './LawyerSlideMobile'
import ContactSlideMobile from './ContactSlideMobile'
import downArrow from '../img/down-arrow.png'

const SEL = 'custom-section'
const SECTION_SEL = `.${SEL}`

class FullPageSlidesMobile extends React.Component {
  constructor(props) {
    super(props)
  }

  moveSectionDown() {
    window.fullpage_api.moveSectionDown()
  }

  renderAnchor(name, i) {
    return i === 0 ? `${name}` : `${name}-${i + 1}`
  }

  render() {
    const { homepage, lawyers, imprintLabel, locale } = this.props
    const contentColumnsData = homepage.data.content_columns
    const lawyersData = lawyers.edges[0].node.data.body
    const heroImages = homepage.data.hero_images
    const contactData = homepage.data.contact
    const isDE = locale === 'de-de'
    const anchors = ['intro'].concat(
      contentColumnsData
        .map((item, i) => {
          return isDE ? this.renderAnchor(`kanzlei`, i) : this.renderAnchor(`expertise`, i)
        })
        .concat(
          lawyersData.map((item, i) => {
            return isDE ? this.renderAnchor(`rechtsanwaelte`, i) : this.renderAnchor(`lawyers`, i)
          })
        )
        .concat(isDE ? 'kontakt' : 'contact')
    )

    const IntroDownNav = () => (
      <div
        className="fullpage-slides__intro-down-nav"
        style={{
          position: 'fixed',
          zIndex: 100,
          top: `95%`,
          left: `50%`
        }}
      >
        <button onClick={() => this.moveSectionDown()}>
          <img src={downArrow} alt="down arrow"></img>
        </button>
      </div>
    )

    return (
      <div className="fullpage-slides fullpage-slider--mobile">
        <IntroDownNav />
        <ReactFullpage
          licenseKey={process.env.GATSBY_FULLPAGE_LICENSE_KEY}
          anchors={anchors}
          sectionSelector={SECTION_SEL}
          normalScrollElements={`.ReactModal__Content`}
          render={comp => (
            <ReactFullpage.Wrapper>
              <div className={SEL} style={{ backgroundColor: `rgb(15, 15, 15)` }}>
                <IntroGallerySlider heroImages={heroImages} />
              </div>
              {contentColumnsData &&
                contentColumnsData.map((column, i) => (
                  <div className={SEL}>
                    <ExpertiseSlideMobile {...column} />
                  </div>
                ))}
              {lawyersData &&
                lawyersData.map((lawyer, i) => (
                  <div className={SEL}>
                    <LawyerSlideMobile {...lawyer} lawyerIndex={i} />
                  </div>
                ))}
              <div className={SEL}>
                <ContactSlideMobile
                  contactData={contactData}
                  imprintLabel={imprintLabel}
                  locale={locale}
                />
              </div>
            </ReactFullpage.Wrapper>
          )}
        />
      </div>
    )
  }
  componentDidMount() {
    if (window !== undefined) {
      setTimeout(() => {
        document.body.classList.add('show-nav')
      }, 300)
    }
  }
}

export default FullPageSlidesMobile
