import React, { useEffect, useRef, useState } from 'react'
import { Fade } from 'react-slideshow-image'
import Logo from '../img/bb-logo-hero.svg'
import LogoShort from '../img/bb-logo-hero--short.svg'
import ImageLoader from '../components/ImageLoader'
import { getSrcSetUrl, getUncompressedUrl } from '../utils/helpers'

function IntroGallerySlider(props) {
  const { heroImages } = props
  const [galleryResized, setGalleryResized] = useState(false)
  const containerRef = useRef(null)
  useEffect(() => {
    // ios vh offset
    const container = containerRef.current
    const fpHeight = container.parentNode.style.height
    const imgs = document.getElementsByClassName('intro-gallery__img')
    Array.from(imgs).map(img => {
      img.style.minHeight = fpHeight
      img.style.maxHeight = fpHeight
    })
    setGalleryResized(true)
  })

  return (
    <section
      className={`intro-gallery hero ${galleryResized ? 'is-gallery-resized' : ''}`}
      ref={containerRef}
    >
      <Fade duration={3000} arrows={false} pauseOnHover={false}>
        {heroImages &&
          heroImages.map((obj, i) => {
            const url = obj.hero_image.url
            return (
              <div className="each-fade" key={`hero-image--${i}`}>
                <div className="intro-gallery__slide image-container">
                  <ImageLoader
                    classes="intro-gallery__img"
                    src={getUncompressedUrl(url)}
                    setSet={`${getSrcSetUrl(url, 2400)} 2400w,${getSrcSetUrl(
                      url,
                      1920
                    )} 1920w,${getSrcSetUrl(url, 1024)} 1024w, ${getSrcSetUrl(
                      url,
                      640
                    )} 640, ${getSrcSetUrl(url, 480)} 480`}
                    alt={obj.alt ? obj.alt : ''}
                  />
                </div>
              </div>
            )
          })}
      </Fade>
      <div className="intro-gallery__logo hero-body">
        <div onClick={props.moveSectionDown}>
          <Logo className="bbb_logo" />
          <LogoShort className="bbb_logo--short" />
        </div>
      </div>
    </section>
  )
}

export default IntroGallerySlider
