import React, { useState, useEffect } from 'react'

function ContactSlideDesktop(props) {
  const { contactData } = props
  const [mapVisible, setMapVisible] = useState(false)
  return (
    <section className={`hero contact ${mapVisible ? 'is-map-visible' : ''}`}>
      {/* contact map */}
      <iframe
        className={`contact__map ${mapVisible ? 'is-visible' : ''}`}
        src="https://snazzymaps.com/embed/90303"
        frameborder="0"
        style={{
          width: `100vw`,
          height: `100vh`
        }}
      />
      <div className="hero-body">
        <div className="container contact__inner">
          <div
            className="contact__address"
            dangerouslySetInnerHTML={{ __html: contactData[0].address.html }}
            onMouseEnter={() => setMapVisible(true)}
            onMouseLeave={() => setMapVisible(false)}
          />
          <div className="contact__links">
            <p>
              <a href={`tel:${contactData[0].telephone}`}>Tel. {contactData[0].telephone}</a>
            </p>
            <p>
              <a href={`fax:${contactData[0].fax}`}>Fax {contactData[0].fax}</a>
            </p>
            <p>
              <a href={`mailto:${contactData[0].email}`}>{contactData[0].email}</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactSlideDesktop
