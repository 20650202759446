import React, { useState, useEffect } from 'react'
import LawyerOverlay from './LawyerOverlay'
import Modal from 'react-modal'
import modalStyle from '../styles/modal-style'
import Close from '../img/close.svg'

function LawyersSlideDesktop(props) {
  const lawyersData = props.lawyers.edges[0].node.data.body
  const [lawyerModalOpen, setLawyerModalOpen] = React.useState(false)
  const [lawyerHover, setLawyerHover] = useState(null)
  const [lawyerIndex, setLawyerIndex] = React.useState(0)

  const openLawyerModal = () => {
    setLawyerModalOpen(true)
  }

  const closeLawyerModal = () => {
    setLawyerModalOpen(false)
  }

  const onLawyerClick = i => {
    setLawyerIndex(i)
    openLawyerModal()
  }

  useEffect(() => {
    Modal.setAppElement('body')
  })

  return (
    <section className={`hero lawyers`} data-hovered={`${lawyerHover}`}>
      <div className="hero-body">
        <div className="container lawyers__inner">
          <Modal
            isOpen={lawyerModalOpen}
            onRequestClose={closeLawyerModal}
            contentLabel={`${lawyersData[lawyerIndex].primary.name}`}
            style={modalStyle}
            closeTimeoutMS={400}

          >
            <a className="overlay-close" onClick={closeLawyerModal}>
              <Close />
            </a>
            <LawyerOverlay lawyer={lawyersData[lawyerIndex].primary} />
          </Modal>
          {lawyersData &&
            lawyersData.map((item, i) => (
              <div key={`lawyer--${i}`}>
                <a
                  className={`lawyers__link ${lawyerHover === i ? 'is-hovered' : ''}`}
                  data-lawyer={i}
                  onClick={() => onLawyerClick(i)}
                  onMouseEnter={() => setLawyerHover(i)}
                  onMouseLeave={() => setLawyerHover('')}
                >
                  {item.primary.name}
                </a>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default LawyersSlideDesktop
