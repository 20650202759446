import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'
import Modal from 'react-modal'
import ImprintPrivacyOverlay from '../components/ImprintPrivacyOverlay'
import modalStyle from '../styles/modal-style'
import { partInViewport } from '../utils/helpers'
import FullPageSlidesDesktop from '../components/FullPageSlidesDesktop'
import FullPageSlidesMobile from '../components/FullPageSlidesMobile'
import { isMobile } from 'react-device-detect'
import Close from '../img/close.svg'

const Index = ({
  data: { homepage, lawyers, imprintPrivacy },
  pageContext: { locale },
  location
}) => {
  // translation
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const imprintLabel = i18n.imprintPrivacy

  // hero images
  // const heroImageData = homepage.data.hero_images
  // const heroImages = heroImageData.map(item => item.hero_image)
  // content columns
  // const contentColumnsData = homepage.data.content_columns
  // lawyers
  // const lawyersData = lawyers.edges[0].node.data.body
  // imprint privacy
  // const imprintPrivacyData = imprintPrivacy.edges[0].node.data
  // contact
  // const contactData = homepage.data.contact
  // const data = [homepage, lawyers, imprintPrivacy]

  const [imprintModalOpen, setImprintModalOpen] = React.useState(false)

  useEffect(() => {
    Modal.setAppElement('body')
    if (window.location && !isMobile) {
      if (window.location.hash === '#imprint' || window.location.hash === '#impressum') {
        setImprintModalOpen(true)
      }
    }
  })

  const openImprintModal = () => {
    setImprintModalOpen(true)
  }

  const closeImprintModal = () => {
    setImprintModalOpen(false)
  }

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} />
      {/* fp slider */}
      {isMobile ? (
        <FullPageSlidesMobile
          homepage={homepage}
          lawyers={lawyers}
          locale={locale}
          imprintLabel={imprintLabel}
        />
      ) : (
        <FullPageSlidesDesktop homepage={homepage} lawyers={lawyers} locale={locale} />
      )}
      {/* imprint modal */}
      <a onClick={openImprintModal}>{`${i18n.imprintPrivacy}`}</a>
      <Modal
        isOpen={imprintModalOpen}
        onRequestClose={closeImprintModal}
        contentLabel={`${i18n.imprintPrivacy}`}
        style={modalStyle}
        closeTimeoutMS={400}
      >
        <a className="overlay-close" onClick={closeImprintModal}>
          <Close />
        </a>
        <ImprintPrivacyOverlay locale={locale} />
      </Modal>
    </>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.object.isRequired,
    social: PropTypes.object.isRequired,
    lawyers: PropTypes.object.isRequired,
    imprintPrivacy: PropTypes.object.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
}

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        hero_images {
          hero_image {
            alt
            copyright
            dimensions {
              height
              width
            }
            url
          }
        }
        content {
          html
        }
        content_columns {
          content_column {
            html
          }
        }
        contact {
          email
          fax
          telephone
          address {
            html
          }
        }
      }
    }
    lawyers: allPrismicLawyers(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            body {
              primary {
                name
                email
                position
                phone
                profile_image {
                  url
                }
                profile_text {
                  html
                }
              }
            }
          }
        }
      }
    }
    imprintPrivacy: allPrismicImprintAndPrivacy(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            imprint {
              html
            }
            privacy_policy {
              raw {
                text
              }
            }
          }
        }
      }
    }
  }
`

// social: allPrismicHeroLinks(filter: { lang: { eq: $locale } }) {
//   edges {
//     node {
//       data {
//         body {
//           primary {
//             label {
//               text
//             }
//             link {
//               url
//             }
//           }
//         }
//       }
//     }
//   }
// }
