export function partInViewport(el, wO, hO) {
  let x = el.getBoundingClientRect().left
  let y = el.getBoundingClientRect().top
  let ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  let hw = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  let w = wO ? el.clientWidth + wO : el.clientWidth
  let h = hO ? el.clientHeight + hO : el.clientHeight
  return y < hw && y + h > 0 && x < ww && x + w > 0
}

export function getUncompressedUrl(url) {
  return url.replace('?auto=compress,format', '?auto=format')
}

export function getSrcSetUrl(url, w) {
  return url.replace('?auto=compress,format', `?w=${w}?auto=format`)
}
