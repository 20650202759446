import React from 'react'

class ImageLoader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  onLoad() {
    this.setState({
      loaded: true
    })
  }

  render() {
    const { loaded } = this.state
    const { src, srcSet, classes, alt } = this.props

    return (
      <img
        src={src}
        srcSet={srcSet}
        className={`${classes ? classes : ''} ${loaded ? 'loaded' : 'loading'}`}
        onLoad={e => {
          this.onLoad()
        }}
        alt={alt ? alt : ''}
      />
    )
  }
  componentDidMount() {
    if (!this.state.loaded) {
      this.onLoad()
    }
  }
}

export default ImageLoader
