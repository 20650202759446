import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import IntroGallerySlider from './IntroGallerySlider'
import ExpertiseSlideDesktop from './ExpertiseSlideDesktop'
import LawyersSlideDesktop from './LawyersSlideDesktop'
import ContactSlideDesktop from './ContactSlideDesktop'
import downArrow from '../img/down-arrow.png'

const SEL = 'custom-section'
const SECTION_SEL = `.${SEL}`

class FullPageSlidesDesktop extends React.Component {
  constructor(props) {
    super(props)
  }

  moveSectionDown() {
    window.fullpage_api.moveSectionDown()
  }

  render() {
    const { homepage, lawyers, locale } = this.props
    const contentColumnsData = homepage.data.content_columns
    const heroImages = homepage.data.hero_images
    const contactData = homepage.data.contact
    const anchors =
      locale === 'de-de'
        ? ['intro', 'kanzlei', 'rechtsanwaelte', 'kontakt']
        : ['intro', 'expertise', 'lawyers', 'contact']

    const IntroDownNav = () => (
      <div
        className="fullpage-slides__intro-down-nav"
        style={{
          position: 'fixed',
          zIndex: 100,
          top: `95%`,
          left: `50%`
        }}
      >
        <button onClick={() => this.moveSectionDown()}>
          <img src={downArrow} alt="down arrow"></img>
        </button>
      </div>
    )

    return (
      <div className="fullpage-slides fullpage-slider--desktop">
        <IntroDownNav />
        <ReactFullpage
          licenseKey={process.env.GATSBY_FULLPAGE_LICENSE_KEY}
          anchors={anchors}
          sectionSelector={SECTION_SEL}
          normalScrollElements={`.ReactModal__Content`}
          // onLeave={this.onLeave.bind(this)}
          render={comp => (
            <ReactFullpage.Wrapper>
              <div className={SEL} style={{ backgroundColor: `rgb(15, 15, 15)` }}>
                <IntroGallerySlider
                  heroImages={heroImages}
                  moveSectionDown={this.moveSectionDown}
                />
              </div>
              <div className={SEL}>
                <ExpertiseSlideDesktop contentColumnsData={contentColumnsData} />
              </div>
              <div className={SEL}>
                <LawyersSlideDesktop lawyers={lawyers} />
              </div>
              <div className={SEL}>
                <ContactSlideDesktop contactData={contactData} />
              </div>
            </ReactFullpage.Wrapper>
          )}
        />
      </div>
    )
  }

  componentDidMount() {
    if (window !== undefined) {
      setTimeout(() => {
        document.body.classList.add('show-nav')
      }, 300)
    }
  }
}

export default FullPageSlidesDesktop
