import React, { Component } from 'react'
import ImageLoader from '../components/ImageLoader'
import AnimateHeight from 'react-animate-height'
import Close from '../img/close.svg'
import ReactFullpage from '@fullpage/react-fullpage'
import { getUncompressedUrl, getSrcSetUrl } from '../utils/helpers'

class LawyerSlideMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      infoHeight: 0,
      imageOpacity: 1,
      imageDisplay: `block`
    }
    this.containerRef = React.createRef()
    this.toggleRef = React.createRef()
    this.infoRef = React.createRef()
    this._onTouchMove = this._onTouchMove.bind(this)
  }

  _onTouchMove(e) {
    const slideEl = this.containerRef.current.parentNode.parentNode
    if (!slideEl.classList.contains('active')) {
      setTimeout(() => {
        this.toggleOut()
      }, 600)
    }
  }

  toggleIn = () => {
    document.body.classList.add('is-mob-lawyer-info-in')
    this.setState({
      imageOpacity: 0
    })
    setTimeout(() => {
      this.setState({
        infoHeight: 'auto',
        imageDisplay: 'none'
      })
    }, 400)
  }

  toggleOut = () => {
    document.body.classList.remove('is-mob-lawyer-info-in')
    this.setState({
      infoHeight: 0
    })
    setTimeout(() => {
      this.setState({
        imageOpacity: 1
      })
    }, 600)
    setTimeout(() => {
      this.setState({
        imageDisplay: 'inline'
      })
    }, 400)
  }

  toggle = () => {
    const { infoHeight } = this.state
    const isOut = infoHeight === 0
    if (isOut) {
      this.toggleIn()
    } else {
      this.toggleOut()
    }
  }

  render() {
    const { infoHeight } = this.state
    const lawyer = this.props.primary
    const lawyerIndex = this.props.lawyerIndex
    const url = lawyer.profile_image.url

    return (
      <section
        className={`hero lawyer-slide-mobile ${infoHeight !== 0 ? 'is-info-open' : ''}`}
        ref={this.containerRef}
        onTouchMove={this._onTouchMove}
      >
        <div className="hero-body lawyer-slide-mobile__inner">
          <button
            className="lawyer-slider-mobile__info-close"
            onClick={this.toggle}
            aria-controls={`lawyer-${lawyerIndex}-info`}
          >
            <Close />
          </button>
          <button
            className="profile-image"
            onClick={this.toggle}
            aria-expanded={infoHeight !== 0}
            aria-controls={`lawyer-${lawyerIndex}-info`}
            ref={this.toggleRef}
            style={{
              opacity: `${this.state.imageOpacity}`,
              display: `${this.state.imageDisplay}`
            }}
          >
            <ImageLoader
              src={getUncompressedUrl(url)}
              setSet={`${getSrcSetUrl(url, 1280)} 640, ${getSrcSetUrl(url, 960)} 480`}
              alt={lawyer.name}
            />
          </button>
          <p>{lawyer.name}</p>
          <AnimateHeight
            className="lawyer-slider-mobile__info"
            id={`lawyer-${lawyerIndex}-info`}
            duration={600}
            height={infoHeight}
          >
            <div ref={this.infoRef}>
              <p className="lawyer-slider-mobile__position">{lawyer.position}</p>
              <div
                className="lawyer-slider-mobile__profile-text"
                dangerouslySetInnerHTML={{ __html: lawyer.profile_text.html }}
              />
            </div>
          </AnimateHeight>
          <p className="lawyer-slider-mobile__email">
            <a href={`mailto:${lawyer.email}`}>{lawyer.email}</a>
          </p>
        </div>
      </section>
    )
  }
}

export default LawyerSlideMobile
